body {
    font: 16px "microsoft yahei", Arial, Helvetica, sans-serif;
    color: #666;
    margin: 0px;
    padding: 0px;
    overflow: auto;
    /* overflow-x: hidden; */
}

@media screen and (max-width:1200px) {
    body {
        width: 1200px;
    }
}

ul,
li,
p,
h1,
h2,
h3,
h4,
h5,
h6,
dl,
dt,
dd,
input,
a {
    margin: 0px;
    padding: 0px;
    border: none;
    list-style: none;
}

img {
    border: 0px;
}

.lf {
    float: left;
}

.rf {
    float: right;
    margin-left: 10px;
}

.clear {
    clear: both;
}

a {
    display: inline-block;
    text-decoration: none;
    color: #666;
}

* {
    box-sizing: border-box;
}

.flex-row-top {
    display: flex;
    flex-direction: row;
    align-items: start;
}


.flex-row-center {
    display: flex;
    flex-direction: row;
    align-items: center;
}


.flex-column-center {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.flex-justify-between {
    justify-content: space-between;
}

.flex-justify-center {
    justify-content: center;
}

.font-18 {
    font-size: 18px;
}

.font-14 {
    font-size: 14px;
}

.font-12 {
    font-size: 12px;
}

.font-42 {
    font-size: 42px;
}

.mt-14 {
    margin-top: 14px;
}

.mr-54 {
    margin-right: 54px;
}

.mr-20 {
    margin-right: 20px;
}

.mt-78 {
    margin-top: 78px;
}

.pd-10 {
    padding: 10px;
}

.pl-20 {
    padding-left: 20;
}

.pt-30 {
    padding-top: 30px;
}

.pt-78 {
    padding-top: 78px;
}

.color-gray {
    color: #999999;
}

.color-black {
    color: #000;
}

.text-center {
    text-align: center;
}

/**/


.home {
    min-width: 1198px;
}


.page-wrapper {
    width: 1198px;
    margin: 0px auto;
}



.head {
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 1000;
    height: 78px;
    line-height: 74px;

}

.head.scroll {
    background-color: #fff;
    border-bottom: 2px solid #ECEFF2;
}

.head .nav {
    width: 600px;
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-between;
}

.head .nav a {
    color: #000;
    width: 80px;
    text-align: center;
}

.head .logo {
    line-height: 74px;
    height: 74px;
}

.head .logo img {
    vertical-align: middle;
}

.head.scroll .nav a.selected {
    color: #0668FD;
    border-bottom: 4px solid #0668FD;
}

.head .btn-logins {
    min-width: 140px;
}

.head .btn-logins span {}

.head .btn-logins button {
    width: 86px;
    height: 30px;
    border-radius: 16px;
    border: 0px;
    background-color: rgba(0, 0, 0, 0);
    font-size: 16px;
}

.head .btn-logins .btn-select {
    background-color: #0668FD;
    color: #fff !important;
}

.home .h1 {
    height: calc(100vh - 99px);
    min-height: 780px;
    max-height: 880px;
    background: url(../asserts/images/head-bg.png) 100% no-repeat;
    padding-top: 162px;
    background-size: 100%;
}

.home .h2 {
    height: 870px;
    background: #Fff;
}

.home .slogan {
    /* margin: 84px 0px 0px 10px; */
}

.home .slogan .title {
    width: 616px;
    height: 144px;
    line-height: 72px;
    color: rgba(0, 0, 0, 1);
    font-size: 56px;
    text-align: left;
}

.home .slogan .intro {
    margin-top: 35px;
    width: 460px;
    height: 137px;
    line-height: 30px;
    color: rgba(0, 0, 0, 1);
    font-size: 16px;
    text-align: left;
}

.home .h1 button {
    width: 166px;
    height: 52px;
    font-size: 16px;
}

.home .h1 button.primary {
    border-radius: 27px;
    background-color: #0668FD;
    border: 0px;
    color: #fff;
}

.home .h1 button.default {
    border-radius: 27px;
    background-color: #fff;
    border: 2px solid #000;
    margin-left: 52px;
}


.home .h2 {
    height: 800px;
}

.home .h2-t1 {
    position: relative;
    top: -80px;
}

.home .h2-t1-item {
    padding: 20px;
    width: 270px;
    height: 176px;
    background-color: #ffffff;
    text-align: center;
    border-radius: 20px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.18);
}

.home .h2-t2 img {
    width: 572px;
    /*height: 547px; */
}

.home .h2-t2-content {
    margin-top: 24px;
    margin-bottom: 46px;
    line-height: 30px;
    font-size: 18px;
    width: 464px;
}


.home .learn-more {
    width: 165px;
    height: 48px;
    font-size: 16px;
    border-radius: 27px;
    border: 2px solid #000000;
    background: #ffffff;
}

.home .h3,
.home .h4 {
    border-top: 3px solid #ECEFF2;
    height: 679px;
    padding: 96px 0px 0px 0px;
}

.home .h5 {
    background-color: #EBEEF2;
    padding: 17px 0px 108px 0px;
}

.home .h5 img {
    width: 387px;
}

.home .h5 .title {
    font-size: 42px;
    margin: 27px 0px 48px 0px;
}

.home .h5 .content {
    font-size: 18px;
    margin: 30px 0px 0px 0px;
}



.home .h6 {
    padding-top: 143px;
    height: 613px;
    background: url(../asserts/images/h6-bg.png) 100% no-repeat;
    background-size: 100%;
}

.home .h6 .title {
    color: #ffffff;
    font-size: 42px;
    line-height: 66px;
    text-align: center;
}


.home .h6 button {
    width: 207px;
    height: 70px;
    border-radius: 35px;
    font-size: 20px;
    border: 0px;
    margin-top: 85px;
    background: #ffffff;
}

/*footer*/

.footer {
    padding: 84px 0px 104px 0px;
}


.footer .content .title {
    margin-bottom: 28px;
}

.footer .content a {
    color: #999999;
    font-size: 14px;
    line-height: 34px;
}

.footer .beian {
    margin-top: 79px;
    border-top: 1px solid #E7ECF3;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding-top: 37px;
}



.market-template {
    padding-top: 100px;
}

.market .item .item-img {
    position: relative;
}

.market .item img {
    width: 100%;
}

.market .item .title {
    padding: 10px;
    position: relative;
}

.market .count {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 14px;
    color: #bbbbbb;
}

.market .item .title .main {
    font-size: 16px;
    line-height: 24px;
    color: #000000;
}

.market .item .title .sub {
    font-size: 14px;
    color: #BBBBBB;
}



.market .item:hover .bg {
    display: flex;
}

.market .item .bg {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    opacity: 0.6;
    display: none;
    background-color: rgba(0, 0, 0, 1);
}

.market .item .bg button {
    width: 112px;
    height: 36px;
    border-radius: 18px;
    color: rgba(255, 255, 255, 100);
    background-color: rgba(0, 0, 0, 0);
}

.market .item .bg button:hover {
    background-color: rgba(255, 255, 255, 1);
    color: #4D4D4D;
}


.market-detail {
    padding-top: 100px;
}


.market-detail .template-info {
    border-left: 2px solid #F0F0F0;
    width: 250px;
    padding-left: 20px;
    height: calc(100vh - 200px);
}

.market-detail .template-info p {
    line-height: 30px;
}


.doc-center {
    padding-top: 100px;
}

.doc-center .doc-tree {
    border-right: 2px solid #F0F0F0;
    width: 250px;
    height: calc(100vh - 200px);
}


.doc-center .doc-tree .ant-tree-title {
    font-size: 14px;
}


.doc-center .doc-content {
    padding-left: 62px;
}

.doc-center .doc-content p {
    line-height: 30px;
}